<template>
  <v-card flat>
    <v-sheet v-if="$vuetify.breakpoint.lgAndUp && !isVertical">
      <v-row no-gutters align="center">
        <v-select
          v-model="school_year_temp"
          :label="$t('school_year.header.school_year')"
          :items="master.schoolYearList"
          :loading="loadingSchoolYear"
          item-value="id"
          class="select-200 d-inline-block mr-2"
          outlined
          dense
          hide-details
          @change="changeFilter('school_year')"
        >
          <template #item="{ item }">
            <span v-if="item.id !== 'all' && !isMaster" class="caption">
              {{ item.start_year }} / {{ item.end_year }}, semester
              {{ item.semester }}
            </span>
            <span v-if="item.id !== 'all' && isMaster" class="caption">
              {{ item.start_year }} / {{ item.end_year }}
            </span>
            <span v-else class="caption">
              {{ item.name }}
            </span>
            <v-tooltip right>
              <template #activator="{ on }">
                <v-icon
                  v-on="on"
                  :color="schoolYearInfo(item.status).color"
                  small
                  class="ml-1"
                  >{{ schoolYearInfo(item.status).name }}</v-icon
                >
              </template>
              <span>{{ schoolYearInfo(item.status).info }}</span>
            </v-tooltip>
          </template>
          <template v-slot:selection="{ item, index }">
            <span
              v-if="index === 0"
              :class="isMaster || 'caption'"
              class="mr-2"
              >{{ appendSemester(item) }}</span
            >
            <span v-if="index === 1" class="grey--text">
              (+{{ filter.school_year.length - 1 }} {{ $t("app.others") }})
            </span>
          </template>
        </v-select>
        <v-select
          v-model="filter.grade"
          :label="$t('school_year.header.select_class')"
          :items="master.gradeList"
          :loading="loadingGrade"
          item-value="id"
          item-text="grade"
          class="select-200 d-inline-block mr-2"
          :multiple="!notMultiple"
          outlined
          dense
          hide-details
          @change="changeFilter('grade')"
        >
          <template v-if="!notMultiple" slot="prepend-item">
            <v-list-item @click="handleClickAll('grade')">
              <v-list-item-action>
                <v-checkbox :value="selectAllGrade"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content :class="selectAllGrade && 'primary--text'">
                {{ $t("app.select_all") }}
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0" class="mr-2">{{ item.grade }}</span>
            <span v-if="index === 1" class="grey--text">
              (+{{ filter.grade.length - 1 }} {{ $t("app.others") }})
            </span>
          </template>
        </v-select>
        <v-select
          v-if="showMajorSelect"
          v-model="majorData"
          :items="majorsItem"
          :label="$t('master_data.student.select_major')"
          item-text="name"
          return-object
          outlined
          dense
          @change="getClassList()"
        />
        <v-select
          v-model="filter.class"
          :disabled="master.classList.length == 0"
          :label="$t('school_year.header.class_type')"
          :items="master.classList"
          item-value="id"
          item-text="name"
          :loading="loadingFilter"
          class="select-200 d-inline-block mr-2"
          outlined
          :multiple="!notMultiple || multipleClass ? true : false"
          dense
          hide-details
          @change="changeFilter('class')"
        >
          <template v-if="!notMultiple" slot="prepend-item">
            <v-list-item @click="handleClickAll('class')">
              <v-list-item-action>
                <v-checkbox :value="selectAllClass"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content :class="selectAllClass && 'primary--text'">
                {{ $t("app.select_all") }}
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0" class="mr-2">{{ item.name }}</span>
            <span v-if="index === 1" class="grey--text">
              (+{{ filter.class.length - 1 }} {{ $t("app.others") }})
            </span>
          </template>
        </v-select>
      </v-row>
    </v-sheet>

    <v-sheet
      v-else-if="
        ($vuetify.breakpoint.lgAndDown && isVertical) ||
          $vuetify.breakpoint.xlOnly
      "
    >
      <v-row
        :justify="widthHorizontal ? 'space-around' : 'start'"
        no-gutters
        align="center"
      >
        <v-select
          v-model="school_year_temp"
          :label="$t('school_year.header.school_year')"
          :items="master.schoolYearList"
          :item-disabled="
            item =>
              itemDisable ? Number(item.id) == itemDisable.school_year : ''
          "
          :loading="loadingSchoolYear"
          item-value="id"
          outlined
          dense
          @change="changeFilter('school_year')"
        >
          <template #item="{ item }">
            <span v-if="item.id !== 'all' && !isMaster" class="caption">
              {{ item.start_year }} / {{ item.end_year }}, semester
              {{ item.semester }}
            </span>
            <span v-if="item.id !== 'all' && isMaster" class="caption">
              {{ item.start_year }} / {{ item.end_year }}
            </span>
            <span v-else class="caption">
              {{ item.name }}
            </span>
            <v-tooltip right>
              <template #activator="{ on }">
                <v-icon
                  v-on="on"
                  :color="schoolYearInfo(item.status).color"
                  small
                  class="ml-1"
                  >{{ schoolYearInfo(item.status).name }}</v-icon
                >
              </template>
              <span>{{ schoolYearInfo(item.status).info }}</span>
            </v-tooltip>
          </template>
          <template v-slot:selection="{ item, index }">
            <span
              v-if="index === 0"
              :class="isMaster || 'caption'"
              class="mr-2"
              >{{ appendSemester(item) }}</span
            >
            <span v-if="index === 1" class="grey--text">
              (+{{ filter.school_year.length - 1 }} {{ $t("app.others") }})
            </span>
          </template>
        </v-select>
        <v-select
          v-model="filter.grade"
          :label="$t('school_year.header.select_class')"
          :items="master.gradeList"
          :loading="loadingGrade"
          :item-disabled="
            item => (itemDisable ? Number(item.id) == itemDisable.grade : '')
          "
          :readonly="itemDisable ? (itemDisable.grade ? true : false) : false"
          :class="
            widthHorizontal
              ? $vuetify.breakpoint.xsOnly
                ? ''
                : 'select-250'
              : ''
          "
          item-value="id"
          item-text="grade"
          outlined
          dense
          @change="changeFilter('grade')"
        >
          <template v-if="!notMultiple && !itemDisable" slot="prepend-item">
            <v-list-item @click="handleClickAll('grade')">
              <v-list-item-action>
                <v-checkbox :value="selectAllGrade"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content :class="selectAllGrade && 'primary--text'">
                {{ $t("app.select_all") }}
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0" class="mr-2">{{ item.grade }}</span>
            <span v-if="index === 1" class="grey--text">
              (+{{ filter.grade.length - 1 }} {{ $t("app.others") }})
            </span>
          </template>
        </v-select>
        <v-select
          v-if="showMajorSelect"
          v-model="majorData"
          :items="majorsItem"
          :label="$t('master_data.student.select_major')"
          item-text="name"
          return-object
          outlined
          dense
          @change="getClassList()"
        />
        <v-select
          v-model="filter.class"
          :disabled="!filter.grade || master.classList.length == 0"
          :label="$t('school_year.header.class_type')"
          :items="master.classList"
          :loading="loadingFilter"
          :multiple="multipleClass"
          :item-disabled="
            item => (itemDisable ? Number(item.id) == itemDisable.class : '')
          "
          :class="
            widthHorizontal
              ? $vuetify.breakpoint.xsOnly
                ? ''
                : 'select-250'
              : ''
          "
          item-value="id"
          item-text="name"
          outlined
          dense
          @change="changeFilter('class')"
        >
          <template v-if="multipleClass && !itemDisable" slot="prepend-item">
            <v-list-item @click="handleClickAll('class')">
              <v-list-item-action>
                <v-checkbox :value="selectAllClass"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content :class="selectAllClass && 'primary--text'">
                {{ $t("app.select_all") }}
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0" class="mr-2">{{ item.name }}</span>
            <span v-if="index === 1" class="grey--text">
              (+{{ filter.class.length - 1 }} {{ $t("app.others") }})
            </span>
          </template>
        </v-select>
      </v-row>
    </v-sheet>
    <v-sheet v-else class="d-flex flex-wrap justify-end">
      <v-btn color="primary" @click="bottomMenu = !bottomMenu" depressed>
        <v-icon>mdi-filter-outline</v-icon>
      </v-btn>
    </v-sheet>

    <v-bottom-sheet v-model="bottomMenu">
      <v-sheet class="text-center pa-6">
        <v-row justify="center">
          <v-col cols="12" sm="4">
            <v-select
              v-model="school_year_temp"
              :label="$t('school_year.header.school_year')"
              :items="master.schoolYearList"
              item-value="id"
              outlined
              dense
              hide-details
              class="mb-3"
              @click:clear="() => (school_year_temp = '')"
              @change="changeFilter('school_year')"
            >
              <template #item="{ item }">
                <span v-if="item.id !== 'all' && !isMaster" class="caption">
                  {{ item.start_year }} / {{ item.end_year }}, semester
                  {{ item.semester }}
                </span>
                <span v-if="item.id !== 'all' && isMaster" class="caption">
                  {{ item.start_year }} / {{ item.end_year }}
                </span>
                <span v-else class="caption">
                  {{ item.name }}
                </span>
                <v-tooltip right>
                  <template #activator="{ on }">
                    <v-icon
                      v-on="on"
                      :color="schoolYearInfo(item.status).color"
                      small
                      class="ml-1"
                      >{{ schoolYearInfo(item.status).name }}</v-icon
                    >
                  </template>
                  <span>{{ schoolYearInfo(item.status).info }}</span>
                </v-tooltip>
              </template>
              <template v-slot:selection="{ item, index }">
                <span
                  v-if="index === 0"
                  :class="isMaster || 'caption'"
                  class="mr-2"
                  >{{ appendSemester(item) }}</span
                >
                <span v-if="index === 1" class="grey--text caption">
                  (+{{ filter.school_year.length - 1 }} {{ $t("app.others") }})
                </span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              v-model="filter.grade"
              :label="$t('school_year.header.select_class')"
              :items="master.gradeList"
              item-value="id"
              item-text="grade"
              :multiple="!notMultiple"
              outlined
              dense
              hide-details
              class="mb-3"
              @change="changeFilter('grade')"
            >
              <template v-if="!notMultiple" slot="prepend-item">
                <v-list-item @click="handleClickAll('grade')">
                  <v-list-item-action>
                    <v-checkbox :value="selectAllGrade"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content
                    :class="selectAllGrade && 'primary--text'"
                  >
                    {{ $t("app.select_all") }}
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="mr-2">{{ item.grade }}</span>
                <span v-if="index === 1" class="grey--text caption">
                  (+{{ filter.grade.length - 1 }} {{ $t("app.others") }})
                </span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              v-model="filter.class"
              :disabled="master.classList.length == 0"
              :label="$t('school_year.header.class_type')"
              :items="master.classList"
              item-value="id"
              item-text="name"
              :loading="loadingFilter"
              outlined
              :multiple="!notMultiple"
              dense
              hide-details
              class="mb-3"
              @change="changeFilter('class')"
            >
              <template v-if="!notMultiple" slot="prepend-item">
                <v-list-item @click="handleClickAll('class')">
                  <v-list-item-action>
                    <v-checkbox :value="selectAllClass"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content
                    :class="selectAllClass && 'primary--text'"
                  >
                    {{ $t("app.select_all") }}
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="mr-2">{{ item.name }}</span>
                <span v-if="index === 1" class="grey--text caption">
                  (+{{ filter.class.length - 1 }} {{ $t("app.others") }})
                </span>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-sheet>
    </v-bottom-sheet>
  </v-card>
</template>

<script>
let typingTimer;
const doneTypingInterval = 750;

import {
  get_school_year_list,
  get_grade_list,
  get_class_list
} from "@/api/admin/schoolClass";

export default {
  props: {
    withInitial: {
      type: Boolean,
      default: true
    },
    isMaster: {
      type: Boolean,
      default: false
    },
    isVertical: {
      type: Boolean,
      default: false
    },
    dataFilter: {
      type: Object,
      default: function() {
        return { year: null, grade: null, class: null };
      }
    },
    resetFilter: Boolean,
    notMultiple: Boolean,
    isHomeroom: Boolean,
    tabHomeroom: Number,
    triggerChange: Boolean,
    multipleClass: Boolean,
    widthHorizontal: Boolean,
    itemDisable: Object,
    showMajorSelect: Boolean,
    itemsMajor: {
      type: Array,
      default: () => []
    },
    mandatoryMajor: Boolean
  },
  computed: {
    majorsItem: {
      get() {
        return this.itemsMajor;
      },
      set(value) {
        this.majorList = value;
      }
    }
  },
  data() {
    return {
      majorList: [],
      majorData: null,
      loadingFilter: false,
      loadingSchoolYear: false,
      loadingGrade: false,
      selectAllGrade: false,
      selectAllClass: false,
      filter: {
        school_year: [],
        grade: [],
        class: []
      },
      master: {
        schoolYearList: [],
        gradeList: [],
        classList: []
      },
      school_year_temp: [],
      schoolYearActive: false,
      bottomMenu: false,
      typeList:
        this.$store.getters.g_role_type === "TEACHER" ? "homeroom_teacher" : ""
    };
  },
  watch: {
    resetFilter(newVal) {
      if (newVal) {
        this.filter = {
          school_year: [],
          grade: [],
          class: []
        };
        this.school_year_temp = null;
        this.majorData = null;
      }
    },
    triggerChange(newVal) {
      if (newVal) this.getSchoolYear();
    },
    dataFilter(nv) {
      if (nv) {
        if (this.notMultiple) {
          this.filter = {
            school_year: nv.year,
            grade: Number(nv.grade),
            class: Number(nv.class)
          };
        } else {
          const gradeList = nv.grade ? nv.grade.split("+") : [];
          const classList = nv.class ? nv.class.split("+") : [];

          this.filter = {
            school_year: nv.year,
            grade: gradeList.map(r => Number(r)),
            class: classList.map(r => Number(r))
          };
        }
      }
    },
    itemDisable(nv) {
      if (nv) {
        this.filter.school_year = this.dataFilter.year;
        this.filter.grade = this.dataFilter.grade;
      }
    }
  },
  mounted() {
    this.$nextTick(async () => {
      await this.getSchoolYear();
      await this.getGradeList();
      await this.getClassList();
    });
  },
  methods: {
    appendSemester(item) {
      let schoolYear = "";
      if (this.isMaster) {
        schoolYear = `${item.start_year} / ${item.end_year}`;
      } else {
        schoolYear = `${item.start_year} / ${item.end_year}, semester ${item.semester}`;
      }
      return schoolYear;
    },
    schoolYearInfo(status) {
      let icon = { name: "", color: "", info: "" };
      const active = {
        name: "mdi-check-circle-outline",
        color: "success",
        info: this.$i18n.t("school_year.current_school_year")
      };
      const willCome = {
        name: "mdi-timer-sand",
        color: "grey",
        info: this.$i18n.t("school_year.upcoming_school_year")
      };
      // const passed = {icon: "mdi-timer-sand", color: "grey"};
      if (this.isMaster) {
        switch (status) {
          case "ACTIVE":
            icon = active;
            break;
          case "WILLCOME":
            icon = willCome;
            break;
          // case "PASSED":
          //   break;
        }
      } else {
        switch (status) {
          case 1:
            icon = active;
            break;
          case 2:
            icon = willCome;
            break;
          // case 0:
          //   icon = passed;
          //   break;
        }
      }
      return icon;
    },
    changeFilter(param) {
      const sy = this.school_year_temp;
      let selectedSchoolYear = null;

      switch (param) {
        case "school_year":
          this.schoolYearActive = false;
          if (sy) {
            this.filter.school_year = [sy];
            selectedSchoolYear = this.master.schoolYearList.filter(
              r => r.id == sy
            )[0];
          }

          this.$emit("handler", {
            type: "school_year",
            data: selectedSchoolYear,
            filter: this.filter,
            master: this.master,
            selected: this.master.schoolYearList.filter(item =>
              this.filter.school_year.some(id => id === item.id)
            )
          });
          this.getClassList();
          break;
        case "grade":
          this.master.classList = [];
          this.filter.class = [];

          this.selectAllGrade = false;
          if (this.master.gradeList.length == this.filter.grade.length) {
            this.selectAllGrade = true;
          }

          this.$emit("handler", {
            type: "select_grade",
            data: this.filter.grade,
            filter: this.filter,
            master: this.master,
            selected:
              this.notMultiple ||
              this.master.gradeList.filter(item =>
                this.filter.grade.some(id => id === item.id)
              )
          });

          if (
            !this.showMajorSelect ||
            this.majorData !== null ||
            !this.mandatoryMajor
          ) {
            this.getClassList();
          }
          break;
        case "class":
          this.selectAllClass = false;
          if (this.master.classList.length == this.filter.class.length)
            this.selectAllClass = true;

          this.$emit("handler", {
            type: "select_class",
            data: this.filter.class,
            filter: this.filter,
            master: this.master,
            selected:
              !this.notMultiple || this.multipleClass
                ? this.master.classList.filter(item =>
                    this.filter.class.some(id => id === item.id)
                  )
                : this.master.classList.find(r => r.class == this.filter.class)
          });
          break;
      }
    },
    handleClickAll(param) {
      if (param == "grade") {
        this.selectAllGrade = !this.selectAllGrade;
        this.filter.grade = [];
        if (this.selectAllGrade)
          this.filter.grade = this.master.gradeList.map(r => r.id);

        this.$emit("handler", {
          type: "select_grade",
          data: this.filter.grade,
          filter: this.filter,
          master: this.master,
          selected: this.master.gradeList.filter(item =>
            this.filter.grade.some(id => id === item.id)
          )
        });

        this.getClassList();
      } else {
        this.selectAllClass = !this.selectAllClass;
        this.filter.class = [];
        if (this.selectAllClass) {
          this.filter.class =
            this.master.classList.length > 0
              ? this.master.classList.map(r => r.id)
              : [];
          this.$emit("handler", {
            type: "select_class",
            data: this.filter.class,
            filter: this.filter,
            master: this.master,
            selected: this.master.classList.filter(item =>
              this.filter.class.some(id => id === item.id)
            )
          });
        } else this.selectAllClass = false;
      }
    },
    async getSchoolYear() {
      this.loadingSchoolYear = true;
      const res = await get_school_year_list(this.isMaster);
      if (res.data.code) {
        const data = res.data.data;
        const status = this.isMaster ? "ACTIVE" : 1;
        this.master.schoolYearList = data;
        if (this.withInitial && data.length > 0) {
          const getActiveYear = this.dataFilter.year
            ? Number(this.dataFilter.year)
            : data.find(r => r.status == status)?.id;
          if (getActiveYear) {
            this.school_year_temp = getActiveYear;
            this.filter.school_year = [getActiveYear];
            this.changeFilter("school_year");
          }
        }
      }
      this.loadingSchoolYear = false;
    },
    async getGradeList() {
      this.loadingGrade = true;
      const res = await get_grade_list({ type: this.typeList });
      if (res.data) this.master.gradeList = res.data.data;
      this.loadingGrade = false;
    },
    getClassList() {
      if (
        this.filter.grade?.length > 0 ||
        typeof this.filter.grade === "number"
      ) {
        this.loadingFilter = true;
        clearTimeout(typingTimer);
        typingTimer = setTimeout(() => {
          let query = JSON.parse(JSON.stringify(this.filter));
          if (this.isVertical || this.notMultiple) {
            if (
              typeof query.grade == "number" ||
              typeof query.grade == "string"
            )
              query.grade = [query.grade];
          }
          if (this.isHomeroom) {
            if (this.tabHomeroom == 0) query.type = "homeroom";
            else query.type = "teacher";
          }
          let body = { type: this.typeList.toUpperCase(), ...query };
          if (this.showMajorSelect) body = { ...body, ...this.majorData };

          get_class_list(body, this.isMaster).then(async res => {
            if (res.data.code) this.master.classList = res.data.data;
            if (this.dataFilter && this.dataFilter.class) {
              // let getClass = [];
              // if (this.dataFilter.class.length > 0) {
              //   const splitClass = this.dataFilter.class.split("+");
              //   getClass = splitClass.map(r => Number(r));
              // }

              if (!this.notMultiple) {
                await this.filter.class.map(res => Number(res));
              }
              this.changeFilter("class");
            }
            setTimeout(() => {
              this.$emit("handler", {
                type: "get_class_list",
                data: this.master.classList,
                filter: this.filter,
                master: this.master
              });
            }, 200);

            this.loadingFilter = false;
          });
        }, doneTypingInterval);
      }
    }
  }
};
</script>

<style></style>
